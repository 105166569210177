<template>
  <div class="relative inset-0 lg:w-full flex flex-col h-full">
    <div
      class="sticky top-0 z-10 rounded-b-xl py-5 lg:px-32 bg-brand-side w-full flex justify-between flex-none"
    >
      <div @click="$router.push('/jobs')" class="pl-4">
        <icon
          :path="icon.mdiArrowLeftThin"
          size="24"
          class="text-white cursor-pointer fill-current flex-none"
        />
      </div>
      <p class="text-white text-center grow whitespace-nowrap">Post New Job</p>
    </div>

    <job-form action_type="add" />
  </div>
</template>

<script setup>
import JobForm from "@/components/job/JobForm.vue";
import { mdiArrowLeftThin } from "@mdi/js";
import Icon from "@/components/Icon.vue";
import { ref } from "vue";

const icon = ref({ mdiArrowLeftThin });
</script>

<style lang="css"></style>
